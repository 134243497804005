import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from 'store/actions/authAction'

import Logo from 'ui/Logo'
import Icon from 'ui/Icon'
import Container from 'ui/Container'
import Messages from 'components/Messages'
import Tooltip from 'ui/Tooltip'

class Nav extends Component{
  componentDidMount() {
    const {
      businessSettings
    } = this.props

    if (!document.getElementById('chatScript')) {
      // you'd normally just include the Small Chat script in the footer,
      // but as we're loading the code on componentMount, rather than onLoad,
      // we include the content of that script here
      // see https://embed.small.chat/T2RBF6LN4C04VAKQSPT5.js

      window.Smallchat = {
        config: {
          'slackTeamId':'T2RBF6LN4',
          'scChannelId':'-NRCZgieFUm6WRHvnJx2',
          'slackChannelId':'C04VAKQSPT5',
          'uid':'-MXBntDT7U99Li6DUL8x',
          'planId':'06',
          'accountCreated':1617270117325
        },
        appearance: {
          'brand_color':'#4ba0b3',
          'contact_dismissible':false,
          'contact_enabled':true,
          'contact_field_label':'Business email',
          'contact_immediately':false,
          'contact_prompt':'Add your name and email to make sure you see our reply:',
          'contact_reply':'Thanks {{name}}! You\'ll get a response here or we\'ll contact you at {{contact}}.',
          'custom_css': '.Launcher-button { font-size: 12px !important; min-height: 30px !important; height: 30px !important; top: 22px !important;}',
          'visible': false,
          'hide_logo':true,
          'hide_team_icon':true,
          'launcher_pos':'right',
          'launcher_prompt':'Live chat',
          'launcher_type':'button',
          'messenger_blank':'Send a message, and we\'ll reply as soon as we can.',
          'messenger_entry':'Send a message...',
          'messenger_prompt':'How can we help you?',
          'name_field_label':'Name',
          'offline_greeting':'We’re offline right now but we\'ll contact you as soon as possible.',
          'text_color':'#ffffff'
        },
        behavior: {
          'avatar_config':1,
          'friday':{
            'from':'9:00',
            'to':'17:00'
          },
          'monday':{
            'from':'9:00',
            'to':'17:00'
          },
          'operating_hours':true,
          'saturday':{
            'disabled':true
          },
          'sunday':{
            'disabled':true
          },
          'thursday':{
            'from':'9:00',
            'to':'17:00'
          },
          'timezone':'Europe/London',
          'tuesday':{
            'from':'9:00',
            'to':'17:00'
          },
          'wednesday':{
            'from':'9:00',
            'to':'17:00'
          }}
      }

      const styles = document.createElement('link')
      styles.rel = 'stylesheet'
      styles.href = 'https://static.small.chat/messenger.css'
      document.head.appendChild(styles)

      const chatScript = document.createElement('script')
      chatScript.async = true
      chatScript.src = 'https://static.small.chat/messenger.js'
      chatScript.id = 'chatScript'
      document.body.append(chatScript)

      window.addEventListener('smallchat:ready', () => {
        document.getElementById('Smallchat').classList.add('nav-chat');

        if (businessSettings && businessSettings.business_id) {
          window.Smallchat.set('url', `User location: ${window.location.href} Business: https://superadminv3.openearmusic.com/businesses/${businessSettings.business_id}`)
        }
      })
    } else {
      // we need to show the small chat button,
      // in case it was hidden on logout
      if (typeof(window.Smallchat) === 'function') {
        window.Smallchat('show');
      }
    }
  }

  logout(){
    this.props.history.push('/')
    this.props.dispatch(logout())

    // we need to hide the small chat button on logout
    if (typeof(window.Smallchat) === 'function') {
      window.Smallchat('hide');
    }

  }

  render(){
    const {
      settings,
      user,
      businessSettings
    } = this.props

    const canImport = businessSettings && businessSettings.importer === 1 ? true : false

    return (
      <Container classname="nav">
        <Container classname="nav-left">
          <Logo/>
          <NavLink exact={true} activeClassName="is-active" to="/">Dashboard</NavLink>
          {!settings || (settings.length === 0) || (settings && (settings.hasVenues === 1)) ?
            <NavLink activeClassName="is-active" to="/venues">Venues</NavLink>
            : null
          }
          <NavLink activeClassName="is-active" to="/playlists">Playlists</NavLink>
          <NavLink activeClassName="is-active" to="/library">Library</NavLink>
          {settings && settings.isFitness === 1 ?
            <NavLink activeClassName="is-active" to="/classes">Classes</NavLink>
            : null
          }
          {canImport && (
            <NavLink activeClassName="is-active" to="/importer">Importer</NavLink>
          )}
        </Container>
        <Container classname="nav-right">
          {/* Temp removal for initial launch */}
          {/*<Queue/>*/}
          <Messages/>
          {/* Temp removal for initial launch */}
          {/*<Icon name="settings"/>*/}
          <Icon name="log-in" action={()=>this.logout()}>
            <Tooltip
              pos="left"
              text='Log out'
            />
          </Icon>
          <div>v{process.env.REACT_APP_VERSION}</div>
        </Container>
      </Container>
    )
  }
}
function mapStateToProps(store){
  return {
    settings:store.auth.settings,
    businessSettings:store.auth.businessSettings,
    user: store.auth.user,
    userGroup:store.auth.userGroup
  }
}
export default withRouter(connect(mapStateToProps)(Nav))
